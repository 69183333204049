<template>
  <div class="container">
    <br>
    <div class="row">
      <div class="rc-box-col-15 rc-text-centre rc-font-title">
        Profile
      </div>
    </div>
    <br>
    <div class="row">
      <div class="rc-box-col-5"></div>
      <div class="rc-box-col-5">
        <BackButton v-on:click="changePassword" :disabled="isDisabled" label="Change Password" />
      </div>
      <div class="rc-box-col-5"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import BackButton from '@/components/button/BackButton.vue';
import ConstUtils from '@/utils/ConstUtils.js';

export default {
  name: 'signout',
  components: {
    BackButton,
  },
  data() {
    return {
      isDisabled: false,
    };
  },
  computed: {
    ...mapGetters([
      'auth_client',
      'auth_connected',
      'auth_user',
      ]),
  },
  watch: {
  },
  mounted: function() {
    if (!this.auth_connected) {
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN });
    }
  },
  methods: {
    ...mapMutations([
      'authClear',
    ]),
    changePassword: function() {
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.CHANGE_PASSWORD });
    },
  }  
}
</script>
